var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "value": _vm.shortenText,
      "rows": "18",
      "disabled": "",
      "max-rows": "25"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }