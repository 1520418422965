<template>
  <b-form-textarea
    :value="shortenText"
    class="font-weight-bolder text-uppercase"
    rows="18"
    disabled
    max-rows="25"
    style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
  />
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  props: {
    shortenText: {
      type: [String, undefined],
      default: () => 'NO RECORD DATA',
    },
  },
}
</script>
